import React, { useState } from "react";
import GlobalStyle from "styles/GlobalStyle";
import Theme from "styles/Theme";
import styled, { ThemeProvider } from "styled-components";
import Navbar from "../components/Navbar";

const Wrapper = styled.div`
  height: ${(props) => props.height}px;
  width: 100vw;
  position: fixed;
  overflow: hidden;
`;

const FrameContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const IndexPage = () => {
  const [pageHeight, setPageHeight] = useState(0);

  React.useLayoutEffect(() => {
    // this needs to be done because with SSR we don't have access to window earlier
    if (pageHeight !== document.documentElement.clientHeight) {
      setPageHeight(document.documentElement.clientHeight);
    }
  }, [pageHeight]);

  return (
    <>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Navbar />
        <Wrapper height={pageHeight}>
          <FrameContainer>
            <iframe
              src="https://my-business-106415.square.site"
              style={{ border: "0px" }}
              width="100%"
              height="100%"
              title="booking site"
            ></iframe>
          </FrameContainer>
        </Wrapper>
      </ThemeProvider>
    </>
  );
};

export default IndexPage;

export const Head = () => <title>Project Essence</title>;
